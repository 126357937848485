import { get } from "lodash";
import { correctLineOfBusiness } from "@/forms/utils";

export const tableColumns = {
  "quote.agentCode": {
    formatDataKey: "Agency ID",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 150,
    position: 0
  },
  quoteApplicationData: {
    formatDataKey: "Client Name",
    formatDataValue: (arg: any) => {
      if (arg && arg.applicant) {
        const { firstName = "", lastName = "" } = arg?.applicant || {};
        if (firstName && lastName) {
          return `${firstName} ${lastName}`;
        }
      }
      return "N/A";
    },
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1
  },
  "quote.status": {
    formatDataKey: "Status",
    formatDataValue: (arg: string) => {
      const first = arg[0].toLocaleUpperCase();
      return first + arg.substring(1, arg.length);
    },
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 150,
    position: 2
  },
  "quote.policyType": {
    formatDataKey: "LOB",
    formatDataValue: (arg: string) => correctLineOfBusiness(arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 100,
    position: 3
  },
  "quote.riskAddress.county": {
    formatDataKey: "County",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 150,
    position: 4
  },
  "capeDataResponse.status.error_code": {
    formatDataKey: "Cape Request Status",
    formatDataValue: (arg: string) => (arg === "OK" ? "HAS ANALYTICS" : arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 180,
    position: 5
  },
  "capeDataEvaluationResponse.flaggedItems": {
    formatDataKey: "Flagged Data Points",
    formatDataValue: (arg: any = {}) =>
      Object.keys(arg)
        .map((str: string = "") => str.replace(/_/g, ""))
        .join(", "),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 200,
    position: 6
  },
  "capeDataEvaluationResponse.companyNumber": {
    formatDataKey: "Status (Pass or Fail)",
    formatDataValue: (_arg: any, args: any) => {
      const flagged = get(args, "capeDataEvaluationResponse.flaggedItems", {});
      const errorCode = get(args, "capeDataResponse.status.error_code", "");
      let isFlagged = "✅";
      const iconHtml = (icon: any) =>
        `<div style='width:100%;text-align:center'> ${icon} </div>`;

      if (errorCode != "OK") {
        isFlagged = "❌";
        return iconHtml(isFlagged);
      }
      if (!Object.keys(flagged).length) {
        return iconHtml(isFlagged);
      }
      Object.keys(flagged).map((name: string) => {
        if (!["pool_presence", "trampoline_presence"].includes(name)) {
          isFlagged = "❌";
        }
      });
      return iconHtml(isFlagged);
    },
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 180,
    position: 7
  },
  "quote.yearBuild": {
    formatDataKey: "Year Built",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 120,
    position: 8
  }
};
